import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Title, Button, Section, Box, Text, Input } from '../components/Core';

import PageWrapper from '../components/PageWrapper';
import { Helmet } from 'react-helmet';
import Hero from '../sections/contactus/Hero';

const FormStyled = styled.form``;

const Contact1 = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Helmet>
                    <title data-react-helmet="true">Contact Us - EMPG LABS</title>
                    <meta
                        name="description"
                        content="Get in touch width EMPG LABS. EMPG LABS is the tech arm of the group that is building scalable solutions and handles millions of users across the globe. We have a tech team of 500+ members across the globe."
                    />
                    <meta property="og:url" content="https://www.empglabs.com/contact-us" />
                    <meta property="og:title" content="About Us - EMPG LABS" />
                    <meta
                        property="og:description"
                        content="EMPG LABS is the tech arm of the group that is building scalable solutions and handles millions of users across the globe. We have a tech team of 500+ members across the globe."
                    />
                    <meta data-react-helmet="true" property="og:image" content="" />
                    <link rel="canonical" href="https://www.empglabs.com/contact-us" />
                </Helmet>
                <Hero title="Contact Us"></Hero>
                <Section>
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="6">
                                <FormStyled
                                    name="contact1"
                                    method="post"
                                    data-netlify="true"
                                    data-netlify-honeypot="bot-field"
                                >
                                    {/* You still need to add the hidden input with the form name to your JSX form */}
                                    <input type="hidden" name="form-name" value="contact1" />

                                    <Box mb={5}>
                                        <Title>Get in touch.</Title>
                                    </Box>
                                    <Box mb={3}>
                                        <Input
                                            type="text"
                                            placeholder="Your name"
                                            name="name"
                                            required
                                        />
                                    </Box>
                                    <Box mb={3}>
                                        <Input
                                            type="email"
                                            placeholder="Email address"
                                            name="email"
                                            required
                                        />
                                    </Box>

                                    <Box mb={3}>
                                        <Input
                                            type="text"
                                            placeholder="Subject"
                                            name="subject"
                                            required
                                        />
                                    </Box>

                                    <Box mb={3}>
                                        <Input
                                            type="text"
                                            as="textarea"
                                            placeholder="Write your message"
                                            rows={4}
                                            name="message"
                                            required
                                        />
                                    </Box>

                                    <Button width="100%" type="submit" borderRadius={10}>
                                        Send Message
                                    </Button>
                                </FormStyled>
                            </Col>
                            <Col lg={5} className="offset-lg-1 order-lg-2 mt-5 pt-lg-5">
                                <Box className="mb-5 mt-4">
                                    <Title variant="card" fontSize="24px">
                                        Call us
                                    </Title>
                                    <Text>042-36400081</Text>
                                </Box>
                                <Box className="mb-5">
                                    <Title variant="card" fontSize="24px">
                                        Email us
                                    </Title>
                                    <Text>
                                        <a href="mailto:info@empglabs.com">info@empglabs.com</a>
                                    </Text>
                                </Box>
                                <Box className="mb-5">
                                    <Title variant="card" fontSize="24px">
                                        Location
                                    </Title>
                                    <Text fontSize="18px">
                                        <span className="font-weight-bold">Lahore:</span> 8th Floor,
                                        Mega Tower, 63-B, Main Boulevard, Gulberg II, Lahore
                                    </Text>
                                    <Text fontSize="18px" mt="4">
                                        <span className="font-weight-bold">Islamabad: </span>
                                        Executive Heights, 65 West, AKM Fazl-ul-Haq Rd, G 7/2 Blue
                                        Area, Islamabad Capital Territory
                                    </Text>
                                    <Text fontSize="18px" mt="4">
                                        <span className="font-weight-bold">Karachi:</span> 501, 5th
                                        Floor, Syedna Tahir Saifuddin Memorial Building, Beaumont
                                        Road, Civil Lines, Karachi
                                    </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default Contact1;
